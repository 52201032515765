<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h2 class="underdashed text-2xl">Life and Death</h2>
      <p>
        There is no life without death. Nutrients are being recycled and it does not matter if it is about
        fauna, flora or fungi. Nature uses checks and balances to avoid any one species gain the upper hand
        and extinct another species. In nature members of any species die rarely at the end of their life span
        but more often by attack from predatory species, accidents or from illness caused by bacteria and viruses.
      </p>
      <p>
        We mimic a natural ecosystem and for the purpose of delivering food to our human customers we
        protect plants and animals from disease and we remove plants and animals to transform them into food.
        That means we harvest plants and animals and the non-editable parts go back into the ecosystem as
        nutrients as much as the current laws allow us to do so.
      </p>
      <p>
        Animal based food products are more an outcome of what we do but are not the primary product. Here and there we
        also have to remove an animal from the system due to disease or behavior in order to protect the others and the
        system as a whole.
      </p>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
export default {
  name: "LifeDeath",
  components: {
    Sidebar
  },
};
</script>
